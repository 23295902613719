import request from '@/utils/request';

// 列表
export function List(params) {
  return request({
    url: '/admin/form/installation/page',
    method: 'GET',
    params
  });
}

// 详情
export function Detail(params) {
  return request({
    url: '/admin/form/installation/detail',
    method: 'GET',
    params
  });
}

// 员工列表
export function GetAdminList(params) {
  return request({
    url: '/admin/collocation/admin/getList',
    method: 'GET',
    params
  });
}

// 工程师列表
export function InstallationEngineerList(params) {
  return request({
    url: '/admin/collocation/admin/installationEngineerList',
    method: 'GET',
    params
  });
}

// 工单上的二维码
export function DetailQRcode(params) {
  return request({
    url: '/admin/form/installation/detailQRcode',
    method: 'GET',
    params
  });
}

// 通过
export function Pass(data) {
  return request({
    url: '/admin/form/installation/pass',
    method: 'POST',
    data
  });
}

// 拒绝
export function NoPass(data) {
  return request({
    url: '/admin/form/installation/noPass',
    method: 'POST',
    data
  });
}

// 创建巡检单
export function InspectionSave(data) {
  return request({
    url: '/admin/form/installation/createOnSiteFrom',
    method: 'POST',
    data
  });
}

// 创建维修单
export function ServiceSave(data) {
  return request({
    url: '/admin/form/installation/createRepairFrom',
    method: 'POST',
    data
  });
}
