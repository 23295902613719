<template>

  <div class="page-cu-container">
    <div class="page-cu-main">
      <a-row type="flex" class="page-cu-row">
        <a-col :span="24" class="page-cu-left">
          <div class="page-cu-left-head">
            <a-form layout="inline">
              <a-form-model-item>
                <a-input v-model="page.key" placeholder="请输入关键字"></a-input>
              </a-form-model-item>
              <a-form-model-item>
                <a-range-picker @change="changeDate" />
              </a-form-model-item>
              <a-form-model-item>
                <a-select allowClear style="width: 200px" v-model="page.company_id" placeholder="请选择工单提交人">
                  <a-select-option v-for="(item, index) in adminList" :value="item.id" :key="index">{{ item.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item>
                <a-select allowClear style="width: 200px" v-model="page.admin_id" placeholder="请选择安装工程师">
                  <a-select-option v-for="(item, index) in engineerList" :value="item.id" :key="index">{{ item.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item>
                <a-button type="primary" @click="search">搜索</a-button>
              </a-form-model-item>
            </a-form>
          </div>
          <div class="page-cu-left-table">
            <vuescroll>
              <a-table :columns="columns" :data-source="list" rowKey="id" bordered :pagination="false">
                <template slot="is_apply" slot-scope="text, record">
                    <a-tag color="blue" v-if="text == '1'">未审核</a-tag>
                    <a-tag color="green" v-if="text == '2'">审核通过</a-tag>
                    <a-tag color="#f50" v-if="text == '3'">审核拒绝</a-tag>
                </template>
                <template slot="state" slot-scope="text, record">
                  <a-tag color="blue" v-if="text == '1'">待安装</a-tag>
                  <a-tag color="green" v-if="text == '2'">安装完成</a-tag>
                </template>
                <template slot="installDate" slot-scope="text, record">
                  <span>{{record.start_time}} ~ {{record.end_time}}</span>
                </template>
                <template slot="action" slot-scope="text, record">
                  <a-dropdown >
                    <a class="ant-dropdown-link" @click.stop.prevent="e => e.preventDefault()">
                      更多操作
                      <a-icon type="down"/>
                    </a>
                    <a-menu slot="overlay">
                      <a-menu-item v-if="record.is_apply == '1'">
                        <a-button type="link" @click="openPass(record.id)" icon="check-circle">通过</a-button>
                      </a-menu-item>
                      <a-menu-item v-if="record.is_apply == '1'">
                        <a-button type="link" icon="minus-circle" @click="openNoPass(record.id)">拒绝</a-button>
                      </a-menu-item>
                      <a-menu-item>
                        <a-button type="link" @click="openDetail(record)" icon="eye">详情</a-button>
                      </a-menu-item>
                      <a-menu-item>
                        <a-button type="link" @click="openInspection(record.id)" icon="plus">创建巡检单</a-button>
                      </a-menu-item>
                      <a-menu-item>
                      <a-button type="link" @click="openService(record.id)" icon="plus">创建维修单</a-button>
                    </a-menu-item>
                    </a-menu>
                  </a-dropdown>
                </template>

              </a-table>
              <div class="page-cu-left-page">
                <a-pagination
                  v-model="page.start"
                  :page-size.sync="page.pageSize"
                  :total="page.totalRow"
                  @change="changePage"
                />
              </div>
            </vuescroll>
          </div>
        </a-col>
      </a-row>
    </div>

    <a-modal title="拒绝操作" @ok="actionNoPass" :width="400" :destroyOnClose="true" :confirmLoading="$store.state.requestLoading" :visible="noPassVisible" @cancel="cancel">
      <a-form-model
        :destroyOnClose="true"
        ref="noPass"
        :rules="noPassRules"
        :model="noPass"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 16 }"
      >
        <a-form-model-item
          label="拒绝理由"
          prop="nopass_content"
        >
          <a-input type="textarea" :rows="4" v-model="noPass.nopass_content"></a-input>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <a-modal title="详情" :width="800" :destroyOnClose="true" :confirmLoading="$store.state.requestLoading"
             :visible="detailVisible" :footer="null" @cancel="cancel">
      <table class="cu-table" id="print-table">
        <tr><td class="field-left">工单创建人</td><td>{{record.admin_name}}</td></tr>
        <tr><td class="field-left">审核状态</td>
          <td>
            <span v-if="record.is_apply == '1'">未审核</span>
            <span v-if="record.is_apply == '2'">审核通过</span>
            <span v-if="record.is_apply == '3'">审核拒绝</span>
          </td>
        </tr>
        <tr v-if="record.is_apply == '3'">
          <td class="field-left">拒绝理由</td>
          <td><span>{{ record.nopass_content }}</span></td>
        </tr>
        <tr>
          <td class="field-left">安装状态</td>
          <td>
            <span v-if="record.state == '1'">待安装</span>
            <span v-if="record.state == '2'">安装完成</span>
          </td>
        </tr>
        <tr><td class="field-left">客户公司</td><td>{{record.company_name}}</td></tr>
        <tr><td class="field-left">客户名称</td><td>{{record.custom_name}}</td></tr>
        <tr><td class="field-left">联系电话</td><td>{{record.custom_mobile}}</td></tr>
        <tr><td class="field-left">安装产品</td>
          <td>
            <div v-for="(item, index) in record.product_json" :key="index">
              <div><span>产品名称: </span><span>{{item.name}}</span></div>
              <div><span>机器型号: </span><span>{{item.machine_model}}</span></div>
              <div><span>供货单位: </span><span>{{item.delivery_unit}}</span></div>
              <div><span>品牌: </span><span>{{item.report_name}}</span></div>
            </div>
          </td>
        </tr>
        <tr><td class="field-left">二维码</td><td><img :src="record['qr']" alt="" style="width: 200px;height: 200px"></td></tr>
        <tr><td class="field-left">安装工程师</td><td>{{record.engineer_name}}</td></tr>
        <tr><td class="field-left">安装开始时间</td><td>{{record.start_time}}</td></tr>
        <tr><td class="field-left">安装结束时间</td><td>{{record.end_time}}</td></tr>
        <tr><td class="field-left">保修时间</td><td>{{record.warranty_period}} 月</td></tr>
        <tr><td class="field-left">保修提醒时间</td><td><span>{{ record.warranty_reminder_time }}</span></td></tr>
        <tr><td class="field-left">备注</td><td>{{record.content}}</td></tr>
        <tr><td class="field-left">创建时间</td><td>{{record.create_time}}</td></tr>
      </table>
      <div style="margin: 20px;display: flex;justify-content: center"><a-button v-print="'#print-table'">打印</a-button></div>
    </a-modal>
    <a-modal title="创建巡检单" @ok="actionInspection" :width="600" :destroyOnClose="true" :confirmLoading="$store.state.requestLoading" :visible="inspectionVisible" @cancel="cancel">
      <a-form-model
        :destroyOnClose="true"
        ref="inspection"
        :rules="inspectionRules"
        :model="inspection"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 16 }"
      >
        <a-form-model-item
          label="超期时间"
          prop="exceed_time"
        >
          <a-input v-model="inspection.exceed_time" placeholder="请输入大于0的超期时间"></a-input>
        </a-form-model-item>
        <a-form-model-item label="巡检时间" prop="on_site_end_time" style="width: 100%">
          <a-range-picker @change="changeXjDate" />
        </a-form-model-item>
        <a-form-model-item
          label="巡检工程师"
          prop="on_site_admin_id"
        >
        <a-select allowClear style="width: 100%" v-model="inspection.on_site_admin_id" placeholder="请选择巡检工程师">
          <a-select-option v-for="(item, index) in engineerList" :value="item.id" :key="index">{{ item.name }}</a-select-option>
        </a-select>
        </a-form-model-item>
        <a-form-model-item
          label="内容"
          prop="content"
        >
          <a-input type="textarea" :rows="4" v-model="inspection.content"></a-input>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <a-modal title="创建维修单" @ok="actionService" :width="600" :destroyOnClose="true" :confirmLoading="$store.state.requestLoading" :visible="serviceVisible" @cancel="cancel">
      <a-form-model
        :destroyOnClose="true"
        ref="service"
        :rules="serviceRules"
        :model="service"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 16 }"
      >
        <a-form-model-item label="维修时间" prop="end_time" style="width: 100%">
          <a-range-picker @change="changeWxDate" />
        </a-form-model-item>
        <a-form-model-item
          label="维修工程师"
          prop="repair_id"
        >
          <a-select allowClear style="width: 100%" v-model="service.repair_id" placeholder="请选择维修工程师">
            <a-select-option v-for="(item, index) in engineerList" :value="item.id" :key="index">{{ item.name }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item
          label="内容"
          prop="content"
        >
          <a-input type="textarea" :rows="4" v-model="service.content"></a-input>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
  import * as Api from './api';
  import axios from 'axios';

  export default {
    name: "index",
    data() {
      return {
        columns: [
          {
            title: '工单创建人',
            dataIndex: 'admin_name',
            width: 120
          },
          {
            title: '客户公司',
            dataIndex: 'company_name'
          },
          {
            title: '客户名称',
            dataIndex: 'custom_name'
          },
          {
            title: '联系电话',
            dataIndex: 'custom_mobile',
            width: 150
          },
          {
            title: '审核状态',
            dataIndex: 'is_apply',
            scopedSlots: {customRender: 'is_apply'},
          },
          {
            title: '安装状态',
            dataIndex: 'state',
            scopedSlots: {customRender: 'state'},
            width: 100
          },
          {
            title: '安装工程师',
            dataIndex: 'engineer_name',
            scopedSlots: {customRender: 'engineer_name'},
            width: 120
          },
          {
            title: '安装开始～结束时间',
            dataIndex: 'installDate',
            scopedSlots: {customRender: 'installDate'},
            width: 250
          },
          {
            title: '创建时间',
            dataIndex: 'create_time',
            width:200
          },
          {
            title: '操作',
            width: 150,
            scopedSlots: {customRender: 'action'}
          },
        ],

        page: {
          start: 1,
          totalRow: 0,
          pageSize: 0,
          limit:20,
          start_time: '',
          end_time: '',
          admin_id: undefined,
          on_site_admin_id: undefined,
          key: ''
        },
        noPassVisible: false,
        noPass: {
          id: '',
          nopass_content: ''
        },
        noPassRules: {
          nopass_content: [
            { required: true, message: "请输入拒绝理由", trigger: "change" },
          ]
        },
        list: [],
        adminList: [],
        engineerList: [],
        detailVisible: false,
        record: {},
        inspectionVisible: false,
        inspection: {
          installation_id: '',
          exceed_time: '',
          on_site_start_time: '',
          on_site_end_time: '',
          on_site_admin_id: undefined,
          content: ''
        },
        inspectionRules: {
          exceed_time: [
            { required: true, message: "请输入超期时间", trigger: "change" },
          ],
          on_site_start_time: [
            { required: true, message: "请选择巡检开始时间", trigger: "change" },
          ],
          on_site_end_time: [
            { required: true, message: "请选择巡检结束时间", trigger: "change" },
          ],
          on_site_admin_id: [
            { required: true, message: "请选择巡检工程师", trigger: "change" },
          ],
          content: [
            { required: true, message: "请输入巡检内容", trigger: "change" },
          ]
        },
        serviceVisible: false,
        service: {
          installation_id: '',
          start_time: '',
          end_time: '',
          repair_id: undefined,
          content: ''
        },
        serviceRules: {
          start_time: [
            { required: true, message: "请选择开始时间", trigger: "change" },
          ],
          end_time: [
            { required: true, message: "请选择结束时间", trigger: "change" },
          ],
          repair_id: [
            { required: true, message: "请选择维修工程师", trigger: "change" },
          ],
          content: [
            { required: true, message: "请输入维修内容", trigger: "change" },
          ]
        }
      }
    },
    mounted() {
      this.getList();
      this.getAdminList();
      this.getInstallationEngineerList();
    },
    methods: {
      /**
       * 打开创建巡检单
       * @author wheat
       * */
      openInspection(id) {
        this.inspection.installation_id = id;
        this.inspection.exceed_time = '';
        this.inspection.on_site_start_time = '';
        this.inspection.on_site_end_time = '';
        this.inspection.on_site_admin_id = undefined;
        this.inspectionVisible = true;
      },
      /**
       * 巡检时间选择
       * @author wheat
       * */
      changeXjDate(e, s) {
        this.inspection.on_site_start_time = s[0];
        this.inspection.on_site_end_time = s[1];
      },
      /**
       * 创建巡检单
       * @author wheat
       * */
      actionInspection() {
        this.$refs['inspection'].validate(async (valid) => {
          if (valid) {
            let res = await Api.InspectionSave(this.inspection);
            if (res && res["code"] == "0") {
              this.inspectionVisible = false;
              this.$message.success(res.message);
              this.getList();
            } else {
              this.$message.error(res.message);
            }
          }
        });
      },
      /**
       * 打开创建维修单
       * @author wheat
       * */
      openService(id) {
        this.service.installation_id = id;
        this.service.start_time = '';
        this.service.end_time = '';
        this.service.content = '';
        this.service.repair_id = undefined;
        this.serviceVisible = true;
      },
      /**
       * 维修时间选择
       * @author wheat
       * */
      changeWxDate(e, s) {
        this.service.start_time = s[0];
        this.service.end_time = s[1];
      },
      /**
       * 创建维修单
       * @author wheat
       * */
      actionService() {
        this.$refs['service'].validate(async (valid) => {
          if (valid) {
            let res = await Api.ServiceSave(this.service);
            if (res && res["code"] == "0") {
              this.serviceVisible = false;
              this.$message.success(res.message);
              this.getList();
            } else {
              this.$message.error(res.message);
            }
          }
        });
      },
      /**
       * 时间选择
       * @author wheat
       * */
      changeDate(e, s) {
        this.page.start_time = s[0];
        this.page.end_time = s[1];
      },
      /**
       * @author 打开详情
       * */
      openDetail(record) {
        this.detailVisible = true;
        this.record = record;
        this.detailVisible = true;
        this.record['product_json'] = JSON.parse(this.record['product_json']);
        this.record['qr'] = process.env.VUE_APP_API + '/admin/form/installation/detailQRcode?id=' + record.id;
        this.detailQRcode();
      },

      async detailQRcode() {
        let res = await Api.DetailQRcode({id: this.record.id})
      },
      /**
       * 通过
       * @author wheat
       * */
      async openPass(id) {
        let that = this;
        this.$confirm({
          title: '信息提示',
          okType: 'primary',
          content: '您确定要通过吗?',
          async onOk() {
            let res = await Api.Pass({id: id})
            if (res && res.code == '0') {
              that.$message.success(res.message);
              that.getList();
            } else {
              that.$message.error(res.message);
            }
          },
          onCancel() {
          },
        });
      },
      /**
       * 打开拒绝操作
       * @author wheat
       * */
      openNoPass(id) {
        this.noPass.id = id;
        this.noPass.nopass_content = '';
        this.noPassVisible = true;
      },
      /**
       * 拒绝操作
       * @author wheat
       * */
      actionNoPass() {
        this.$refs['noPass'].validate(async (valid) => {
          if (valid) {
            let res = await Api.NoPass(this.noPass);
            if (res && res["code"] == "0") {
              this.noPassVisible = false;
              this.$message.success(res.message);
              this.getList();
            } else {
              this.$message.error(res.message);
            }
          }
        });
      },
      /**
       * 关闭
       * @author lhx
       * */
      cancel(){
        this.noPassVisible = false;
        this.detailVisible = false;
        this.inspectionVisible = false;
        this.serviceVisible = false;
      },
      /**
       * 搜索
       * @author wheat
       * */
      search() {
        this.getList();
      },

      /**
       * 获取列表
       * @author wheat
       * */
      async getList() {
        let res = await Api.List(this.page)
        this.page.totalRow = res.page.totalRow;
        this.page.pageSize = res.page.pageSize;
        this.list = res.page.list;
      },
      /**
       * 获取员工列表
       * @author wheat
       * */
      async getAdminList() {
        let res = await Api.GetAdminList({});
        this.adminList = res.list;

      },
      /**
       * 获取工程师列表
       * @author wheat
       * */
      async getInstallationEngineerList() {
        let res = await Api.InstallationEngineerList({});
        this.engineerList = res.list;
      },
      /**
       * 分页切换
       * @author wheat
       * */
      changePage(currentPage) {
        this.page.start = currentPage;
        this.getList();
      }
    }
  }
</script>

<style scoped>
.cu-table
{
  border-collapse:collapse;
  width: 100%;
}

.cu-table,th, td
{
  border: 1px solid #000000;
  padding: 10px;
}
.field-left {
  width: 120px;
}
</style>
